import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {Formik, Form, Field} from 'formik'
import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import GoogleMapReact from 'google-map-react'
import MyGreatPlace from './MyPlace.jsx'
import Drive from './Drive.jsx'
import {Line} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useTable, useFilters, useGlobalFilter, usePagination, useAsyncDebounce} from 'react-table'
import ErrorMessage from '../helpers/message'
import Loading from '../helpers/loading'
import {func, number} from 'prop-types'
import {auto, right} from '@popperjs/core'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

function SensorView2(props) {
  const containerRef = useRef(null) // Create a ref for the scroll container
  const [isSubmitting, setSubmitting] = useState(false)
  const [{token}, dispatch] = useDataLayerValue()
  const labels = []
  const chartReference = React.createRef()
  const breadCrumbs = useRef([])
  const hubService = process.env.REACT_APP_MIDAS_HUB
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [isChartLoading, setChartLoading] = useState(true)
  const [loadingMap, setLoadingMap] = useState(false)
  const [defaultProps, setdefaultProps] = useState({
    center: {
      lat: 6.911979146490742,
      lng: 79.92597226721936,
    },
    zoom: 18,
  })
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])
  const [DeviceType, setDeviceType] = useState('24h')
  const [InitialLoading2, setInitialLoading2] = useState(true)
  const filter = useRef('0')
  const objBrowser = useRef(null)
  const gpsData = useRef([])
  const [PlayCount, setPlayCount] = useState(0)
  const [PlayUpToCount, setPlayUpToCount] = useState(-1)
  //const PlayCount = useRef(0)
  const [data, setdata] = useState([])
  //const gps_data = useRef([])
  const [gps_data, setgps_data] = useState([])
  const isPlaying = useRef(false) //setIsPlaying] = useState(false)
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  })

  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })

  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    factories: [],
    subSectorName: '',
    subSectorCode: '',
    sectorName: '',
    sectorCode: '',
    factroyName: '',
    factroyCode: '',
    unitName: '',
    unitCode: '',
    Sensor: [],
    Packet: '',
    Field: '',
    data: [],
    //gpsdata: [],
  })

  const [live, setLive] = useState({
    Live: '',
    Min: '',
    Max: '',
    Status: '',
    Note: '',
    Description: '',
    NormalRange: '',
    WarningRange: '',
    CriticalRange: '',
    DisableRange: '',
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
    name: '',

    option: {
      responsive: true,
      plugins: {
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 60,
              yMax: 60,
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 2,
            },
          },
        },
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Line Chart',
        },
        bezierCurve: false,
        elements: {
          line: {
            tension: 0,
          },
        },
      },
    },
    data: {
      labels,
      datasets: [
        {
          label: '',
          data: [],
          lineTension: 0,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    },
  })

  function TablesWidget10() {
    const columns = React.useMemo(
      () => [
        {
          Header: ' ',
          columns: [
            {
              accessor: 'code',
            },
            {
              accessor: 'formatCode',
            },
            {
              accessor: 'description',
            },
            {
              accessor: 'createdOn',
            },
            {
              accessor: 'status',
            },
            {
              accessor: 'priority',
            },
            {
              accessor: 'fK_SectorCode',
            },
            {
              accessor: 'facilityName',
            },
            {
              accessor: 'remain',
            },
          ],
        },
      ],
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: {pageIndex, pageSize, globalFilter},
      preGlobalFilteredRows,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        initialState: {pageIndex: 0, pageSize: 20, globalFilter: ''},
      },
      useGlobalFilter,
      usePagination
    )
    const [error, setError] = useState({status: 'E', text: ''})

    const [{user, token}, dispatch] = useDataLayerValue()

    if (InitialLoading2) return <Loading />

    return (
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Last 24h Alerts</span>
            <span className='text-muted mt-1 fw-bold fs-7'> Alert Management</span>
          </h3>
          <ErrorMessage error={error} />
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <div className='me-4'></div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              {...getTableProps()}
            >
              <thead>
                <td className='text-gray-600 text-gray-800 fw-bolder'>Ticket</td>
                <td className='text-gray-600 text-gray-800 fw-bolder'>Description</td>
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 && (
                  <tr>
                    <td colSpan={7}>
                      <b>No Data Found!</b>
                    </td>
                  </tr>
                )}
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {/* {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}*/}
                      <td style={{width: '120px'}}>
                        {
                          <a className='btn btn-sm  fw-bolder  fs-9 py-1'>
                            {row.values.formatCode}
                          </a>
                        }
                      </td>
                      <td>
                        <b>{row.values.description}</b>{' '}
                        <span
                          style={{fontSize: '10px'}}
                          className='btn btn-sm btn-light-info fw-bolder  fs-9 py-1'
                        >
                          {row.values.priority}
                        </span>
                        {
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-info fw-bolder  ms-2 fs-9 py-1'
                          >
                            {row.values.facilityName}
                          </a>
                        }
                        <br />
                        <span style={{fontSize: '10px'}}> {row.values.createdOn}</span>
                        {row.values.status == 'Opened' && (
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-9 py-1 px-3'
                          >
                            {' '}
                            OPENED ({row.values.remain})
                          </a>
                        )}
                        {row.values.status == 'Resolved' && (
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-9 py-1 px-3'
                          >
                            {' '}
                            RESOLVED
                          </a>
                        )}
                        {row.values.status == 'Closed' && (
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-gray fw-bolder ms-2 fs-9 py-1 px-3'
                          >
                            {' '}
                            CLOSED
                          </a>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* 
      Pagination can be built however you'd like. 
      This is just a very basic UI implementation:
    */}
            <ul className='pagination'>
              <li className='page-item' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <a className='page-link'>First</a>
              </li>
              <li className='page-item' onClick={() => previousPage()} disabled={!canPreviousPage}>
                <a className='page-link'>{'<'}</a>
              </li>
              <li className='page-item' onClick={() => nextPage()} disabled={!canNextPage}>
                <a className='page-link'>{'>'}</a>
              </li>
              <li
                className='page-item'
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <a className='page-link'>Last</a>
              </li>
              <li>
                <a className='page-link'>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </a>
              </li>
              <li>
                <a className='page-link'>
                  <input
                    className='page-link'
                    type='number'
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      gotoPage(page)
                    }}
                    style={{width: '100px', height: '20px'}}
                  />
                </a>
              </li>{' '}
              <select
                className='page-link'
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
                style={{width: '120px', height: '38px'}}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </ul>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    )
  }
  const [countdown, setCountdown] = useState(60)
  const [countdown2, setCountdown2] = useState(0)
  // useEffect(() => {
  //   if (connection) {
  //     connection
  //       .start()
  //       .then((result) => {
  //         setState({
  //           ...state,
  //           DashboardStat: 'CONNECTED',
  //         })
  //         setLoading(false)
  //         reconnectRef.current = 0
  //         connection.onreconnecting((error) => {
  //           setState({
  //             ...state,
  //             DashboardStat: 'RECONNECTING',
  //           })
  //           console.log('Connection lost due to error . Reconnecting.')
  //         })

  //         // Reconnect if hub disconnects
  //         connection.onclose(function (e) {
  //           setState({
  //             ...state,
  //             DashboardStat: 'DISCONNECTED',
  //           })
  //           //console.log('Disconnected');
  //         })

  //         connection.on('ReceiveMessage', (message) => {
  //           if (typeof message === 'string' || message instanceof String) {
  //             message = JSON.parse(message)
  //           }
  //           if (message.key !== 'SensorData') return

  //           let dataPacket = JSON.parse(message.data)
  //           if (
  //             dataPacket.TimeStamp === undefined ||
  //             Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 10
  //           ) {
  //             return
  //           }

  //           console.log(message)
  //           let newElements = {}
  //           Object.entries(dataPacket).map(([key, value]) => {
  //             newElements[key] = value
  //           })

  //           Object.entries(dataPacket).map(([key, value]) => {
  //             for (let index = 0; index < sensors.current.length; index++) {
  //               const element = sensors.current[index]
  //               if (element.machineNo === '') continue

  //               if (element.displayName === '') element.displayName = ' '

  //               if (element.icon === undefined) element.icon = 'dot'

  //               if (
  //                 element.isNotify === undefined ||
  //                 element.isNotify === '' ||
  //                 element.isNotify === null
  //               )
  //                 element.isNotify = false

  //               if (!element.lastUpdate) {
  //                 element.lastUpdate = new Date()
  //               } else if (Math.round((new Date() - new Date(element.lastUpdate)) / 1000) > 60) {
  //                 // console.log('[DB PACKET ' + element.machineNo.toString().toLowerCase() + ']')
  //                 if (
  //                   element.type == 14 ||
  //                   element.type == 13 ||
  //                   element.type == 17 ||
  //                   element.type == 15
  //                 ) {
  //                   //console.log("[chart data cleared "+element.machineNo.toString().toLowerCase()+"]");
  //                   element.value = []
  //                 } else {
  //                   element.value = '---'
  //                   element.color = 'black'
  //                 }
  //               }

  //               let object = element.object.toString().toLowerCase()
  //               let packetType = dataPacket.Plant + '.' + dataPacket.Packet

  //               if (
  //                 element.object.toString().toLowerCase() === key.toString().toLowerCase() &&
  //                 packetType.toString().toLowerCase() === element.pakages
  //               ) {
  //                 element.lastUpdate = new Date()
  //                 element.value = value
  //                 element.color = 'black'
  //                 element.notification = {
  //                   toastId: key.toString().toLowerCase(),
  //                   position: 'top-right',
  //                   type: 'info',
  //                   autoClose: false, // or set time
  //                   hideProgressBar: false,
  //                   closeOnClick: true,
  //                   //  pauseOnHover: true,
  //                   draggable: false,
  //                   progress: undefined,
  //                   transition: Bounce,
  //                 }
  //                 try {
  //                   // alert(layout.globalJs)
  //                   eval(layout.globalJs + ';' + element.formula)
  //                   //   eval(element.formula)
  //                 } catch (error) {
  //                   element.value = '---'
  //                   element.color = 'black'
  //                   // console.log(element.formula)
  //                   console.log(error)
  //                 }
  //               }
  //             }
  //           })
  //           setState({
  //             ...state,
  //             SensorList: sensors.current,
  //             DashboardStat: 'CONNECTED',
  //           })
  //         })
  //       })
  //       .catch((e) => {})
  //   }
  // }, [connection])

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      // Decrease the countdown value every second
      setCountdown((prevCountdown) => (prevCountdown - 1 < 0 ? 0 : prevCountdown - 1))
    }, 1000)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(countdownInterval)
  }, []) // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/v2/sensors/' +
        (props.match?.params?.code ?? 'dream'),
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    }).then((response) => {
      // alert(response.data.result.unitModels[0].object)
      if (response.data.status == 'S') {
        setLayout({
          ...layout,
          //subSectorName: response.data.result.subSectors[0].name,
          //subSectorCode: response.data.result.subSectors[0].code,
          //sectorName: response.data.result.name,
          //sectorCode: response.data.result.code,
          //factroyName: response.data.result.subSectors[0].factories[0].name,
          //factroyCode: response.data.result.subSectors[0].factories[0].code,
          //unitName: response.data.result.businessUnitModels[0].name,
          //unitCode: response.data.result.businessUnitModels[0].code,
          Sensor: response.data.result.unitModels[0],
          breadCrumbs: response.data.result.breadCrumbs,
        })
        objBrowser.current = response.data.result.unitModels[0]
        // setBreadCrum(response)

        RefreshData(response.data.result.unitModels[0])
        // setInterval(() => {
        //   RefreshData(response.data.result.unitModels[0])
        // }, 60000)

        setState({
          ...state,
          SensorList: response.data.result.unitModels,
        })
        sensors.current = response.data.result.unitModels
        setTimeout(() => {
          for (let index = 0; index < sensors.current.length; index++) {
            const element = sensors.current[index]
            try {
              eval(layout.globalJs + ';' + element.tempFeild1)
            } catch (error) {
              console.log(error)
            }
          }
          setState({
            ...state,
            SensorList: sensors.current,
            DashboardStat: 'INITIALIZATION',
          })

          setTimeout(() => {
            const newConnection = new HubConnectionBuilder()
              .withUrl(
                process.env.REACT_APP_MIDAS_HUB +
                  '/' +
                  response.data.result.automationChannel +
                  '/Hub'
              )
              .withAutomaticReconnect()
              .build()
            setConnection(newConnection)
          }, 100)
        }, 500)
      }
    })
  }, [])

  // function RefreshData2() {
  //   //alert('asdasd')
  //   setChartLoading(true)
  //   const chart = chartReference.current
  //   setTimeout(() => {
  //     setChartLoading(false)

  //     chart.data.datasets[0] = [
  //       {
  //         label: '',
  //         data: [],
  //         lineTension: 0,
  //         borderColor: 'rgb(53, 162, 235)',
  //         backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //       },
  //     ][0]
  //     chart.update('none')
  //   }, 10)
  // }

  function RefreshData(obj) {
    if (filter.current === '1') return
    //alert(filter.current)
    if (obj?.name == undefined) return
    const chart = chartReference.current
    const sector = ''
    // axios({
    //   ...axioDefaultConfig,
    //   method: 'get',
    //   url: process.env.REACT_APP_BACKEND_API + '/api/get-alerts-tag?tag=' + obj.code,
    //   headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    // })
    //   .then(function (response) {
    //     setInitialLoading2(false)
    //     setdata(response?.data?.data)
    //   })
    //   .catch(function (error) {})

    var datafield = {}
    datafield['sensorCode'] = obj.code
    datafield['sort'] = 'TimeStamp: 1'
    datafield['find'] = 't_GAS_PERCENT : {$exists:true}, TimeStamp : ' + DeviceType
    datafield['project'] =
      'TimeStamp: 1, ' +
      't_GAS_PERCENT : 1,' +
      't_BAT_VOL : 1,' +
      't_NCU_FW_VER : 1,' +
      't_GW_NAME : 1,' +
      't_RSSI : 1,' +
      't_TANK_SIZE : 1,' +
      't_LATITUDE : 1,' +
      't_LONGITUDE : 1,' +
      obj.object +
      ' : 1, t_' +
      obj.object +
      '_gps : 1, t_' +
      obj.object +
      '_Ignition : 1 '
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_API + '/api/v1/sensor/getall',
      headers: {},
      data: datafield,
    }).then((response) => {
      if (response.data.status == 'S') {
        setChartLoading(false)
        gpsData.current = response.data
        // setLayout({
        //   ...layout,
        //   gpsdata: response.data,
        // })
        const _labels = []
        const _data = []
        const _gps_data = []
        for (let index = 0; index < response.data.result.list.length; index++) {
          const element = response.data.result.list[index]
          _labels.push(element['TimeStamp'])
          _data.push(element['t_GAS_PERCENT'])
        }

        setCountdown(60)
        setLive({
          Live: response.data.result.currrent,
          Min: response.data.result.min,
          Max: response.data.result.max,
          Date: response.data.result.date,
          Status: response.data.result.status,
          Note: response.data.result.note,
          Description: response.data.result.description,
          NormalRange: response.data.result.normalRange,
          WarningRange: response.data.result.warningRange,
          CriticalRange: response.data.result.criticalRange,
          DisableRange: response.data.result.disableRange,
          Lat: response.data.result?.Lat ?? '-',
          Lng: response.data.result?.Lng ?? '-',
          ignition: response.data.result?.ignition ?? '-',
          Bat: response.data.result?.bat ?? '-',
          Bluethooth: response.data.result?.bluethooth ?? '-',
          Version: response.data.result?.version ?? '-',
          TankSize: response.data.result?.tanksize ?? '-',
        })

        _gps_data.push({
          lat: response.data.result?.lat,
          lng: response.data.result?.lng,
          value: response.data.result.currrent + '%',
          text: response.data.result.currrent + '%',
          endDate: '',
          waiting: 0,
          ignition: 'asdas',
        })

        setgps_data(_gps_data)
        setChartData({
          labels: _labels,
          datasets: [
            {
              label: '',
              data: _data,
              lineTension: 0,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 1,
            },
          ],
        })
        setLoading(false)
      }
    })
  }

  function hideAllNotification() {
    toast.dismissAll()
  }

  function hideNotification(notification) {
    toast.dismiss(notification.toastId)
  }

  function getTransactionType(transition) {
    switch (transition.toLowerCase()) {
      case 'flip':
        return Flip
      case 'slide':
        return Slide
      case 'zoom':
        return Zoom

      default:
        return Bounce
    }
  }
  function getType(type) {
    switch (type.toLowerCase()) {
      case 'info':
        return toast.TYPE.INFO
      case 'error':
        return toast.TYPE.ERROR
      case 'success':
        return toast.TYPE.SUCCESS
      case 'warning':
        return toast.TYPE.WARNING
      default:
        return toast.TYPE.INFO
    }
  }

  function showNotification(notification) {
    notification.transition = getTransactionType(notification.transition)
    if (toast.isActive(notification.toastId)) {
      notification.render = notification.message
      notification.type = getType(notification.type)
      toast.update(notification.toastId, notification)
      return
    }

    switch (notification.type) {
      case 'error':
        toast.error(notification.message, notification)
        break
      case 'info':
        toast.info(notification.message, notification)
        break
      case 'warning':
        toast.warn(notification.message, notification)
        break
      case 'success':
        toast.success(notification.message, notification)
        break
      default:
        toast(notification.message)
        break
    }
  }

  function Notification(notification) {
    if (toast.isActive(notification.toastId)) {
      toast.dismiss(notification.toastId)
    }
    switch (notification.Type) {
      case 'error':
        toast.error(notification.Message, {
          toastId: notification?.toastId ?? 'default',
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break

      case 'info':
        toast.info(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'warning':
        toast.warn(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'success':
        toast.success(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      default:
        toast('Wow so easy!')
        break
    }
  }

  const dateConverter = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate)
    const newEndDate = new Date(timeEnd)
    const one_day = 1000 * 60
    let result
    result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / one_day)
    console.log('date Converter result', result)
    if (result < 0) {
      return '0'
    }

    return `${Math.floor(result / 60)}h ${result % 60}min`
  }

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [gps_data])
  // Prevent user from scrolling up (if needed)
  const handleWheel = (event) => {
    if (containerRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = containerRef.current
      // Prevent scrolling up
      if (scrollTop === 0 && event.deltaY < 0) {
        event.preventDefault() // Prevent scrolling up
      }
    }
  }

  function onClearFilter() {
    filter.current = '0'
    setPlayUpToCount(-1)
    setPlayCount(0)
    RefreshData(objBrowser.current)
  }

  function onSelect(index2) {
    setdefaultProps({
      center: {
        lat: Number(gps_data[index2].lat),
        lng: Number(gps_data[index2].lng),
      },
      zoom: 15,
    })
    filter.current = '1'
    setPlayUpToCount(index2)
  }

  var statusColorClass = 'btn btn-sm btn-light-dark'
  if (live.Status == 'NORMAL') {
    statusColorClass = 'btn btn-sm btn-light-success'
  } else if (live.Status == 'WARNING') {
    statusColorClass = 'btn btn-sm btn-light-warning'
  } else if (live.Status == 'CRITICAL') {
    statusColorClass = 'btn btn-sm btn-light-danger'
  }

  var pr = live.Live ?? 0
  var colurx = '#900C3F'
  if (pr > 70) {
    colurx = '#09932c'
  } else if (pr > 45) {
    colurx = '#FFC300'
  } else {
    colurx = '#900C3F'
  }

  return (
    <>
      <PageTitle breadcrumbs={layout.breadCrumbs}>{layout?.Sensor?.name} </PageTitle>
      <div className={`card`}>
        <div className='form'>
          <div className='card-body p-9'>
            <div className='row'>
              <div className=' col-lg-6'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Data Visualized </span> <br />
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {layout?.Sensor?.description}
                    {filter.current == '0' && (
                      <>
                        <b style={{color: 'darkblue'}}>
                          &nbsp; Last Update at {live?.Date} <i class='bi bi-calendar'></i>
                        </b>
                      </>
                    )}
                    {filter.current == '1' && (
                      <>
                        <b style={{color: 'darkblue'}}> &nbsp; Filtered Apply</b>
                        <a href='#' onClick={onClearFilter}>
                          &nbsp;&nbsp; Clear Filers
                        </a>
                      </>
                    )}
                  </span>
                </h3>
              </div>
              <div className='col-lg-1'></div>
              {/* <div className=' col-lg-4'>
                {countdown <= 10 && filter.current == '0' && (
                  <>
                    <b style={{color: 'red'}}>Auto refresh in {countdown} seconds</b>
                  </>
                )}
                {countdown > 10 && filter.current == '0' && (
                  <>
                    <p>Auto refresh in {countdown} seconds</p>{' '}
                  </>
                )}
              </div> */}
              {live.Status == 'CRITICAL' && (
                <div className='col-lg-3'>
                  <div
                    className={
                      'notice d-flex gap-5   rounded  border border-dashed p-6   mb-1  ' +
                      (live.Status == 'CRITICAL' ? 'bg-light-danger border-danger' : ' border-dark')
                    }
                  >
                    <i
                      class={
                        'fas fa-exclamation-triangle fa-3x ' +
                        (live.Status == 'CRITICAL' ? 'text-danger' : ' text-dark')
                      }
                    ></i>
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>Critical Level</h4>
                        <div className='fs-6 text-gray-600'>{live?.CriticalRange} Range</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {live.Status == 'WARNING' && (
                <div className='col-lg-3'>
                  {' '}
                  <div
                    className={
                      'notice d-flex gap-5  rounded  border border-dashed p-6  mb-1 ' +
                      (live.Status == 'WARNING'
                        ? 'bg-light-warning border-warning'
                        : ' border-dark')
                    }
                  >
                    <i
                      class={
                        'fas fa-exclamation-circle  fa-3x ' +
                        (live.Status == 'WARNING' ? 'text-warning' : '  text-dark')
                      }
                    ></i>
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>Warning Level</h4>
                        <div className='fs-6 text-gray-600'>{live?.WarningRange} Range</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {live.Status == 'NORMAL' && (
                <div className='col-lg-3'>
                  {' '}
                  <div
                    className={
                      'notice d-flex  gap-5  rounded  border border-dashed p-6   mb-1  ' +
                      (live.Status == 'NORMAL' ? 'bg-light-success border-success' : ' border-dark')
                    }
                  >
                    <i
                      class={
                        'fas fa-check-circle  fa-3x ' +
                        (live.Status == 'NORMAL' ? 'text-success' : ' text-dark')
                      }
                    ></i>
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>Normal Level</h4>
                        <div className='fs-6 text-gray-600'>{live?.NormalRange} Range</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {live.Status == 'DISABLED' && (
                <div className='col-lg-3'>
                  <div
                    className={
                      'notice d-flex gap-5  rounded border-dark border border-dashed p-6   mb-1  ' +
                      (live.Status == 'DISABLED' ? 'bg-light-dark' : '')
                    }
                  >
                    <i
                      class={
                        'fas fa-times-circle fa-3x ' +
                        (live.Status == 'DISABLED' ? 'text-dark' : 'text-dark')
                      }
                    ></i>
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>Disable Level</h4>
                        <div title={live?.DisableRange} className='fs-6 text-gray-600'>
                          {live?.DisableRange.substring(0, 15) + '...'} Range
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {live.Live && (
                <>
                  <div className='col-lg-2'>
                    <div
                      className={
                        'notice d-flex gap-5  rounded border-dark border border-dashed p-6   mb-1  '
                      }
                    >
                      <table style={{width: '100%'}}>
                        <tr>
                          <td>
                            <div className='me-1'>
                              <div
                                style={{
                                  backgroundColor: colurx,
                                  width: '35px',
                                  height: '45px',
                                  float: 'left',
                                  top: 0,
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: 'grey',

                                    bottom: 0,
                                    width: '100%',
                                    height: 100 - pr + '%',
                                    //border-top: 6px solid #FFF;
                                    boxSizing: 'border-box',
                                    animation: 'grow 1.5s ease-out forwards',
                                    transformOrigin: 'bottom',
                                  }}
                                ></div>
                              </div>
                              <img
                                style={{
                                  width: '40px',
                                  position: 'absolute',
                                  marginLeft: '-38px',
                                  top: '35px',
                                }}
                                src={toAbsoluteUrl('/media/img/gas.png')}
                                alt='Footer'
                              />
                            </div>
                          </td>
                        </tr>
                      </table>
                      <div
                        style={{
                          fontFamily: 'Roboto Mono, monospace',
                          zIndex: 10,
                          fontSize: '30px',
                          width: '100%',
                        }}
                      >
                        {live.Live}%
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className={`card`}>
        <br />
        <Formik
          initialValues={{
            datex: '',
            Ftype: 1440,
          }}
          // validationSchema={}
          onSubmit={(values, {}) => {
            filter.current = '1'
            // alert('HI EX' + values.datex)
            var datafield = {}
            datafield['sensorCode'] = layout.Sensor.code

            datafield['sort'] = 'TimeStamp: 1'
            datafield['date'] = values.datex
            datafield['Ftype'] = values.Ftype
            datafield['find'] = 't_GAS_PERCENT : {$exists:true}, TimeStamp : DT'

            datafield['project'] =
              'TimeStamp: 1, ' +
              't_GAS_PERCENT : 1,' +
              't_BAT_VOL : 1,' +
              't_NCU_FW_VER : 1,' +
              't_GW_NAME : 1,' +
              't_RSSI : 1,' +
              't_TANK_SIZE : 1,' +
              't_LATITUDE : 1,' +
              't_LONGITUDE : 1,' +
              layout.Sensor.object +
              ' : 1, t_' +
              layout.Sensor.object +
              '_gps : 1, t_' +
              layout.Sensor.object +
              '_Ignition : 1 '
            axios({
              method: 'post',
              url: process.env.REACT_APP_BACKEND_API + '/api/v1/sensor/getall',
              headers: {},
              data: datafield,
            }).then((response) => {
              const _labels = []
              const _data = []
              const _gps_data = []
              if (response.data.status == 'S') {
                setChartLoading(false)
                gpsData.current = response.data
                // setLayout({
                //   ...layout,
                //   gpsdata: response.data,
                // })

                for (let index = 0; index < response.data.result.list.length; index++) {
                  const element = response.data.result.list[index]
                  _labels.push(element['TimeStamp'])
                  _data.push(element['t_GAS_PERCENT'])
                  // console.log(element['t_' + obj.object + '_gps'])
                }

                setLive({
                  Live: response.data.result.currrent,
                  Min: response.data.result.min,
                  Max: response.data.result.max,
                  Date: response.data.result.date,
                  Status: response.data.result.status,
                  Note: response.data.result.note,
                  Description: response.data.result.description,
                  NormalRange: response.data.result.normalRange,
                  WarningRange: response.data.result.warningRange,
                  CriticalRange: response.data.result.criticalRange,
                  DisableRange: response.data.result.disableRange,
                  Lat: response.data.result?.Lat ?? '-',
                  Lng: response.data.result?.Lng ?? '-',
                  ignition: response.data.result?.ignition ?? '-',
                  Bat: response.data.result?.bat ?? '-',
                  Bluethooth: response.data.result?.bluethooth ?? '-',
                  Version: response.data.result?.version ?? '-',
                  TankSize: response.data.result?.tanksize ?? '-',
                })

                _gps_data.push({
                  lat: response.data.result?.lat,
                  lng: response.data.result?.lng,
                  value: response.data.result.currrent + '%',
                  text: response.data.result.currrent + '%',
                  endDate: '',
                  waiting: 0,
                  ignition: '',
                })

                setgps_data(_gps_data)

                setChartData({
                  labels: _labels,
                  datasets: [
                    {
                      label: '',
                      data: _data,
                      lineTension: 0,
                      backgroundColor: 'rgba(75,192,192,0.4)',
                      borderColor: 'rgba(75,192,192,1)',
                      borderWidth: 1,
                    },
                  ],
                })
                setLoading(false)
                console.log('response', response)
              } else {
                setgps_data([])
                setChartData({
                  labels: _labels,
                  datasets: [
                    {
                      label: '',
                      data: _data,
                      lineTension: 0,
                      backgroundColor: 'rgba(75,192,192,0.4)',
                      borderColor: 'rgba(75,192,192,1)',
                      borderWidth: 1,
                    },
                  ],
                })
                setLoading(false)
              }
              // const _labels = []
              // const _data = []
              // if (response.data.status == 'S') {
              //   setChartLoading(false)

              //   for (let index = 0; index < response.data.result.list.length; index++) {
              //     const element = response.data.result.list[index]
              //     _labels.push(element['TimeStamp'])
              //     _data.push(element['t_' + layout.Sensor.object])
              //   }

              //   setChartData({
              //     labels: _labels,
              //     datasets: [
              //       {
              //         label: '',
              //         data: _data,
              //         lineTension: 0,
              //         backgroundColor: 'rgba(75,192,192,0.4)',
              //         borderColor: 'rgba(75,192,192,1)',
              //         borderWidth: 1,
              //       },
              //     ],
              //   })
              //   setLoading(false)
              // } else {
              //   setChartData({
              //     labels: _labels,
              //     datasets: [
              //       {
              //         label: '',
              //         data: _data,
              //         lineTension: 0,
              //         backgroundColor: 'rgba(75,192,192,0.4)',
              //         borderColor: 'rgba(75,192,192,1)',
              //         borderWidth: 1,
              //       },
              //     ],
              //   })
              //   setLoading(false)
              // }
            })
            //()
          }}
        >
          {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <div className='form'>
                <div className='card-body p-9'>
                  <div className='row'>
                    <div className=' col-lg-6'>
                      <input
                        type='datetime-local'
                        name='datex'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.datex}
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      />
                    </div>

                    <div className=' col-lg-4'>
                      <select
                        className='form-select form-select-solid  mb-3 mb-lg-0 '
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        name='Ftype'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Ftype}
                        defaultValue={'1440'}
                      >
                        <option></option>
                        <option value='30'>30 Min</option>
                        <option value='60'>01 Hour</option>
                        <option value='360'>06 Hours</option>
                        <option value='1440'>24 Hours</option>
                        <option value='10080'>1 Week</option>
                        <option value='44640'>1 Month</option>
                      </select>
                    </div>
                    <div className='col-lg-2'>
                      <button
                        type='submit'
                        className='btn col-sm-12 btn-primary'
                        disabled={isSubmitting}
                        style={{display: 'block', width: '100%'}}
                      >
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {!isSubmitting && (
                            <span className='indicator-label'>
                              Filter &nbsp; <i class='fas fa-chevron-right fs-1x'></i>
                            </span>
                          )}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                            </span>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                {isChartLoading && <>Loading</>}
                <Line height={100} option={state.option} ref={chartReference} data={chartData} />
                {/* <Line data={chartData} /> */}
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'></div>
            </Form>
          )}
        </Formik>
      </div>
      {/*
      <br />
      <div className={`card`}>
       
        <div className='card-header border-0 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Data Visualized (24h)</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {layout?.Sensor?.description} |
              <b style={{color: 'darkblue'}}>
                Last Update at {live?.Date} <i class='bi bi-calendar'></i>
              </b>
            </span>
          </h3>
        </div>
        
     
        <div className='card-body py-3'></div>
      </div>
         */}
      <br />

      <div className='row'>
        <div className='col-lg-3'>
          {' '}
          <div
            className={
              'notice d-flex gap-5   rounded  border border-dashed p-6   mb-1  border-dark '
            }
          >
            <i class={'fas fa-battery fa-3x text-dark'}></i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Battey Level</h4>
                <div className='fs-6 text-gray-600'>{live?.Bat} </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3'>
          {' '}
          <div
            className={'notice d-flex gap-5  rounded  border border-dashed p-6  mb-1 border-dark'}
          >
            <i class={'fa fa-signal  fa-3x text-dark '}></i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Bluetooth Strength</h4>
                <div className='fs-6 text-gray-600'>{live?.Bluethooth}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3'>
          {' '}
          <div
            className={'notice d-flex  gap-5  rounded  border border-dashed p-6 mb-1  border-dark'}
          >
            <div className='me-1'>
              <img src='/media/img/gas.png' alt='Footer' style={{width: '25px'}} />
            </div>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Tank Size</h4>
                <div className='fs-6 text-gray-600'>{live?.TankSize} </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3'>
          <div
            className={'notice d-flex gap-5  rounded border-dark border border-dashed p-6   mb-1  '}
          >
            <i class={'fa fa-cog fa-3x text-dark '}></i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Version</h4>
                <div title={live?.DisableRange} className='fs-6 text-gray-600'>
                  {live?.Version}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      {gps_data.length > 0 && (
        <>
          <div className={`card`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Map View </span>
                {/* {PlayCount} */}
                <span className='text-muted mt-1 fw-bold fs-7'> </span>
              </h3>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div style={{height: '50vh', width: '100%'}}>
                    <GoogleMapReact
                      bootstrapURLKeys={{key: 'AIzaSyDnw_D-TklrDIO31vLanvrC_R_NHhypnx0'}}
                      // defaultCenter={defaultProps.center}
                      center={{
                        lat: Number(gps_data[gps_data.length - 1]?.lat ?? defaultProps.center.lat),
                        lng: Number(gps_data[gps_data.length - 1]?.lng ?? defaultProps.center.lng),
                      }}
                      //
                      //defaultCenter={{lat: gps_data[0]?.lat, lng: gps_data[0]?.lng}}
                      //
                      defaultZoom={defaultProps.zoom}
                      zoom={defaultProps.zoom}
                    >
                      {gps_data.map((marker, index) => (
                        <MyGreatPlace
                          key={index}
                          lat={marker.lat}
                          lng={marker.lng}
                          text={marker.value}
                          long_text={marker.text}
                          isNotify={gps_data.length - 1 == index}
                          Link={''}
                          Type={''}
                          Value={''}
                        ></MyGreatPlace>
                      ))}
                      {/*
                  { lat :6.9123483,
                   lng:80.0322383,
                    }
                   key={index}
                        lat={marker.lat}
                        lng={marker.lng}
                        text={''}
                        isNotify={''} 
                        Link={''}
                        Type={''}
                        Value={''}
                  
                  {
                    <MyGreatPlace
                      key={1}
                      lat={6.9123483}
                      lng={}
                      text={'gEVAN'}
                      isNotify={''}
                      Link={''}
                      Type={''}
                      Value={0}
                    ></MyGreatPlace>
                  } */}
                    </GoogleMapReact>
                  </div>
                </div>
              </div>
            </div>
            {/* begin::Body */}
          </div>
        </>
      )}

      {/* {JSON.stringify(gps_data)} */}
      <br />
      <div className='row'>
        <div className='col-sm-6'>
          <div className={`card`}>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Minimum Gas Level</span>
                <span className='text-muted mt-1 fw-bold fs-7'>Based On Last 24hours</span>
              </h3>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <div className='btn btn-sm btn-light-warning'>
                  <div
                    style={{
                      fontFamily: 'Roboto Mono, monospace',
                      zIndex: 10,
                      fontSize: '30px',
                    }}
                  >
                    {live.Min}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6'>
          <div className={`card`}>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Maximum Gas Level</span>
                <span className='text-muted mt-1 fw-bold fs-7'>Based On Last 24hours</span>
              </h3>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
              >
                <div className='btn btn-sm btn-light-danger'>
                  <div
                    style={{
                      fontFamily: 'Roboto Mono, monospace',
                      zIndex: 10,
                      fontSize: '30px',
                    }}
                  >
                    {live.Max}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs.current}>{layout?.Sensor?.name} </PageTitle>
      <>
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-12'>
              <br />
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Data Visualized </span>
                <span className='text-muted mt-1 fw-bold fs-7'></span>
              </h3>
            </div>
          </div>
          <div className='row g-5 g-xl-8'>
            <Line height={100} option={state.option} ref={chartReference} data={state.data} />
          </div>
        </>
      </>
    </>
  )
}

export default SensorView2

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useDataLayerValue} from '../../../../DataLayer'
import axios from 'axios'
import {axioDefaultConfig} from '../../../ui/common/commonResource2'
import {stat} from 'fs'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
  _token: '',
}

export function Login(props) {
  console.log('test')
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [{token}, dispatch] = useDataLayerValue()
  const [tempToken, setTempToken] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      //console.log('asaa')
      //setTimeout(() => {
      axios({
        ...axioDefaultConfig,
        method: 'post',
        url: process.env.REACT_APP_BACKEND_API + '/api/accounts/token',
        data: values,
      })
        .then(function (response) {
          if (response?.data?.token !== '' && response?.data?.token !== null) {
            values._token = response?.data?.token
            setTempToken(response?.data?.token)
            dispatch({
              type: 'SET_USER',
              user: response?.data?.user,
            })
            dispatch({
              type: 'SET_TOKEN',
              token: values._token,
            })
            if (response?.data?.user.isEndNode) {
              if (window.location.hostname.toLowerCase() === 'portal.smartgas.biz') {
                history.push('/facility-detail2/' + response?.data?.user.facility)
              } else {
                history.push('/facility-detail/' + response?.data?.user.facility)
              }
            } else {
              history.push('/facility/' + response?.data?.user.facility)
            }
            // Redirect to new page
            //history.push('/facility/' + response?.data?.user.facility)
            return
          } else {
            setLoading(false)
            setSubmitting(false)
            setStatus(response.data.message)
          }
        })
        .catch(function (error) {
          console.log('asaa')
          setLoading(false)
          setSubmitting(false)
          setStatus('The login detail is incorrect')
        })
      //   }, 5000)
    },
  })

  var content = 'INDUSRTYX'
  if (window.location.hostname.toLowerCase() === 'e2eq.lk') {
    content = 'E2EQ'
  }
  if (window.location.hostname.toLowerCase() === 'portal.smartgas.biz') {
    content = 'SMARTGAS'
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {content === 'INDUSRTYX' && (
        <div className='text-center mb-11'>
          <img width={250} src='https://industryx.io/wp-content/uploads/2024/08/logo-2.png' />
        </div>
      )}

      {content === 'E2EQ' && (
        <div className='text-center mb-11'>
          <img src={toAbsoluteUrl('/media/logos/e2eq/E2E_Q_logo.png')} alt='Footer' width={250} />
        </div>
      )}

      {content === 'SMARTGAS' && (
        <div className='text-center mb-11'>
          <img
            src={toAbsoluteUrl('/media/logos/smartgas/E2E_Q_logo.png')}
            alt='Footer'
            width={250}
          />
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              Continue &nbsp;&nbsp;
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action 

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='#' className='link-primary'
         to='/auth/registration'>
          Sign up
        </Link>
      </div>*/}

      {content === 'E2EQ' && (
        <div className='text-center mb-11'>
          <br />
          <img src={toAbsoluteUrl('/media/logos/e2eq/kfe.png')} alt='Footer' width={120} />
        </div>
      )}
      {/* {content === 'SMARTGAS' && (
        <div className='text-center mb-11'>
          <br />
          <img src={toAbsoluteUrl('/media/logos/smartgas/kfe.png')} alt='Footer' width={120} />
        </div>
      )} */}

      {content === 'INDUSTRYX' && (
        <div className='text-center mb-11'>
          <br />
          <img src={toAbsoluteUrl('/media/logos/indusrtyx/kfe.png')} alt='Footer' width={120} />
        </div>
      )}
    </form>
  )

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      {/* begin::Heading */}

      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>industryX IOT</h1>
      </div>
      {/* begin::Heading
       */}
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div></div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
        </div>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            {new Date().getFullYear()} &copy;{' '}
            <a href='https://industryx.io/home-2' className='text-gray-800 text-hover-primary'>
              <img width={80} src='https://industryx.io/wp-content/uploads/2024/08/logo-2.png' />
            </a>
          </a>
        </div>
      </div>
    </form>
  )
}
